var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_vm._m(0),_c('img',{staticClass:"activity-description",attrs:{"src":require("../../assets/clockin/map/activity_description3.png")},on:{"click":function($event){return _vm.showModal('activity-description-modal')}}}),_c('div',{staticClass:"cust-container"},[(_vm.activityInfo)?_c('div',{staticClass:"map-container",style:('background-image:url(' + _vm.activityInfo.bg_img + ')')},[_c('div',{staticClass:"map-box"},[_c('img',{staticClass:"map-img",attrs:{"src":_vm.activityInfo.map_img}}),_c('div',{staticClass:"ornaments"},_vm._l((_vm.activityInfo.pendants),function(item,index){return _c('img',{key:index,staticClass:"ornament-item",style:('top: ' +
              item.y +
              ';left:' +
              item.x +
              ';width:' +
              item.width +
              ';'),attrs:{"src":item.url}})}),0),_c('div',{staticClass:"points"},_vm._l((_vm.activityInfo.points),function(item,index){return _c('span',{key:index},[(item.type == 1)?_c('div',{staticClass:"point-item",staticStyle:{"width":"2.5%"},style:('top:' + item.y + '%;left:' + item.x + '%;')},[_c('img',{staticStyle:{"pointer-events":"none","width":"100%","display":"block"},attrs:{"src":_vm.activityInfo.numberOfDaysCheckedIn > index
                    ? item.point_2_img
                    : item.point_1_img}})]):_vm._e(),(item.type == 2)?_c('div',{staticClass:"point-item",staticStyle:{"width":"2.5%"},style:('top:' +
                item.y +
                '%;left:' +
                item.x +
                '%;width:' +
                item.icon.width +
                ';')},[_c('img',{staticStyle:{"pointer-events":"none","width":"100%","display":"block"},attrs:{"src":_vm.activityInfo.numberOfDaysCheckedIn > index
                    ? item.icon.point_2_img
                    : item.icon.point_1_img}})]):_vm._e()])}),0)])]):_vm._e()]),_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"btn-container"},[_c('div',{staticClass:"btn events-center-btn"},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInUp "}},[(!_vm.loading)?_c('div',[_c('img',{attrs:{"src":require("../../assets/clockin/map/text_events_center.png")}})]):_vm._e()])],1),_c('div',{staticClass:"btn personal-center-btn"},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInUp events-center-btn-delay-500"}},[(!_vm.loading)?_c('div',[_c('img',{attrs:{"src":require("../../assets/clockin/map/text_personal_center.png")}})]):_vm._e()])],1),_c('div',{staticClass:"btn click-to-clockin-btn"},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInUp events-center-btn-delay-1000"}},[(!_vm.loading)?_c('div',[_c('img',{staticClass:"successfully_clockedin",staticStyle:{"transform":"scale(0)"},attrs:{"src":require("../../assets/clockin/map/text_successfully_clockedin.png")}}),_c('img',{attrs:{"src":require("../../assets/clockin/map/text_click_to_clockin.png")}})]):_vm._e()])],1)])]),_c('transition',{attrs:{"leave-active-class":"animate__animated animate__fadeOut ","enter-active-class":"animate__animated animate__fadeIn "}},[(_vm.showModalName != null)?_c('ActivityDescription',{attrs:{"content":_vm.activityInfo.activity_introduction},on:{"modalCloseBtnClick":_vm.modalCloseBtnClick}}):_vm._e()],1),_c('Loading',{attrs:{"show":_vm.loading}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"opacity":"0","position":"absolute","top":"-1000px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/modal/gift_box_bg_1.jpg"),"alt":""}})])}]

export { render, staticRenderFns }