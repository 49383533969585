<template>
  <div>
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="loadingBox" v-if="show">
        <div class="loading">
          <img src="../../assets/loading-2.gif" alt="" style="width: 30px" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Loading2",
  props: ["show"],
};
</script>

<style scoped lang="less">
.animate__fadeInUp {
  animation-duration: 0.5s;
}

.animate__lightSpeedInRight {
  animation-duration: 0.5s;
}

.loadingBox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.6);
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
