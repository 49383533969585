<template>
  <div style="position: relative">
    <!--<img class="bg" src="../../assets/clockin/map/bg.jpg" />-->

    <div style="opacity: 0; position: absolute; top: -1000px">
      <img src="@/assets/modal/gift_box_bg_1.jpg" alt="" style="width: 100%" />
    </div>
    <img
      class="activity-description"
      @click="showModal('activity-description-modal')"
      src="../../assets/clockin/map/activity_description3.png"
    />
    <div class="cust-container">
      <div
        class="map-container"
        v-if="activityInfo"
        :style="'background-image:url(' + activityInfo.bg_img + ')'"
      >
        <div class="map-box">
          <img class="map-img" :src="activityInfo.map_img" />

          <div class="ornaments">
            <img
              class="ornament-item"
              v-for="(item, index) in activityInfo.pendants"
              :key="index"
              :src="item.url"
              :style="
                'top: ' +
                item.y +
                ';left:' +
                item.x +
                ';width:' +
                item.width +
                ';'
              "
            />
          </div>

          <div class="points">
            <!--:src="item.status ? item.url1 : item.url2"-->

            <img
              src="../../assets/clockin/molly.png"
              v-if="activityInfo.numberOfDaysCheckedIn == 0"
              style="
                position: absolute;
                top: 91%;
                left: 16%;
                width: 35px;
                transform: translate(-50%, -50%);
              "
            />
            <span v-for="(item, index) in activityInfo.points" :key="index">
              <div
                v-if="item.type == 1"
                class="point-item"
                style="width: 2.5%"
                :style="'top:' + item.y + '%;left:' + item.x + '%;'"
              >
                <img
                  :src="
                    activityInfo.numberOfDaysCheckedIn > index
                      ? item.point_2_img
                      : item.point_1_img
                  "
                  style="pointer-events: none; width: 100%; display: block"
                />
                <img
                  v-if="activityInfo.numberOfDaysCheckedIn - 1 == index"
                  src="../../assets/clockin/molly.png"
                  style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 35px;
                    transform: translate(-50%, -50%);
                  "
                />
              </div>

              <div
                v-if="item.type == 2"
                @click="openTheLuckyGiftBox(index)"
                class="point-item"
                style="width: 2.5%"
                :style="
                  'top:' +
                  item.y +
                  '%;left:' +
                  item.x +
                  '%;width:' +
                  item.icon.width +
                  ';'
                "
              >
                <img
                  :src="
                    activityInfo.numberOfDaysCheckedIn > index
                      ? item.icon.point_2_img
                      : item.icon.point_1_img
                  "
                  style="pointer-events: none; width: 100%; display: block"
                />

                <img
                  v-if="activityInfo.numberOfDaysCheckedIn - 1 == index"
                  src="../../assets/clockin/molly.png"
                  style="
                    position: absolute;
                    top: 85%;
                    left: 89%;
                    width: 35px;
                    transform: translate(-50%, -50%);
                  "
                />
              </div>
            </span>

            <!--<img-->
            <!--  class="point-item"-->
            <!--  :src="$host + item.url"-->
            <!--  :style="'top: ' + item.y + '%;left:' + item.x + '%;width:2.5%;'"-->
            <!--/>-->
          </div>
        </div>
      </div>
    </div>

    <div class="footer-container">
      <div class="btn-container">
        <div class="btn events-center-btn">
          <transition enter-active-class="animate__animated animate__fadeInUp ">
            <div v-if="!loading" @click="eventsCenterBtnClick">
              <!--<router-link to="/eventsCenter">-->
              <img src="../../assets/clockin/map/text_events_center.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
        <div class="btn personal-center-btn">
          <transition enter-active-class="animate__animated animate__fadeInUp">
            <div v-if="!loading" @click="clockInRecordBtnClick">
              <!--<router-link to="/clockInRecord">-->
              <img src="../../assets/clockin/map/text_personal_center.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
        <div class="btn click-to-clockin-btn">
          <transition enter-active-class="animate__animated animate__fadeInUp ">
            <div v-if="!loading" @click="clockInBtnClick">
              <img
                class="successfully_clockedin"
                src="../../assets/clockin/map/text_successfully_clockedin.png"
                style="transform: scale(0)"
              />

              <!--<router-link to="/clockIn">-->
              <img src="../../assets/clockin/map/text_click_to_clockin.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <ActivityDescription
        @modalCloseBtnClick="modalCloseBtnClick"
        v-if="showModalName != null"
        :content="activityInfo.activity_introduction"
      ></ActivityDescription>
    </transition>
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="modals" v-if="showLuckyGift">
        <div class="plane"></div>
        <div
          class="modal lucky-gift-box-modal"
          :class="collectPointsRes ? 'open' : ''"
        >
          <img class="bg_title" src="@/assets/modal/bg_title2.png" alt="" />
          <img
            class="close-btn"
            style="z-index: 999"
            @click="luckyGiftCloseBtnClick()"
            src="@/assets/close_icon.png"
          />

          <!--<div class="text">-->
          <!--  激动的心颤抖的手， <br />-->
          <!--  积分马上就到手！ <br />-->
          <!--  最高可抽取<span style="color: #eb5e32">{{-->
          <!--    currentGiftBox && currentGiftBox.integral-->
          <!--  }}</span-->
          <!--  >积分哦！-->
          <!--</div>-->

          <div
            class="text"
            style="white-space: pre-line"
            v-html="currentGiftBox && currentGiftBox.gift_box_text"
          ></div>

          <img
            class="openBtn"
            @click="collectPointsClick"
            src="../../assets/modal/open.png"
            alt=""
          />

          <div class="integral-text-box" id="integral-text-box"></div>
        </div>
      </div>
    </transition>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import router from "@/router";
import anime from "animejs";
import ActivityDescription from "@/components/ActivityDescription";
import Loading from "@/components/Loading";
export default {
  name: "ClockInMap",
  components: { ActivityDescription, Loading },
  data: function () {
    return {
      showLuckyGift: false,
      luckyGiftIndex: -1,
      currentGiftBox: null,
      showModalName: null,
      loading: true,
      activityInfo: null,
      numberOfDaysCheckedIn: 5,
      luckyGiftAni: null,
      collectPointsRes: null,
    };
  },
  mounted() {
    let that = this;
    this.$api.get(
      "activity.activity/getActivityById",
      { id: this.$route.params.id },
      (r) => {
        that.activityInfo = r.data.data;

        localStorage.setItem(
          "activity_" + that.activityInfo.id,
          JSON.stringify(that.activityInfo)
        );
        setTimeout(function () {
          that.loading = false;
        }, 500);
      }
    );
  },
  methods: {
    //判断是否领取积分
    judgeCollectPoints() {
      const that = this;
      this.$api.post(
        "activity.activity/judgeCollectPoints",
        { id: this.$route.params.id, pointIndex: this.luckyGiftIndex },
        (r) => {
          console.log(r);
          var data = r.data.data;

          if (data.is_receive_giftbox != 1) {
            that.showLuckyGift = true;
            setTimeout(function () {
              anime({
                targets: ".openBtn",
                scale: [0.7, 1],
                loop: true,
                duration: 1800,
                delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
              });
            }, 500);
          }
        }
      );
    },
    collectPointsClick() {
      // eslint-disable-next-line no-unused-vars
      const that = this;
      this.$api.post(
        "activity.activity/collectPoints",
        { id: this.$route.params.id, pointIndex: this.luckyGiftIndex },
        (r) => {
          console.log(r);
          that.collectPointsRes = r.data.data;
          that.collectPointsAnimation(that.collectPointsRes.giftbox_integral);
        }
      );
    },
    //领取积分的动画
    collectPointsAnimation(integral) {
      this.luckyGiftAni = setInterval(function () {
        var div = document.createElement("div");
        div.className = "integral-text";
        div.innerText = "+" + integral + "积分";
        var container = document.getElementById("integral-text-box");
        container.appendChild(div);

        anime({
          targets: div,
          bottom: ["0%", "100%"],
          opacity: [0.3, 1, 0],
          scale: [1.3, 1.6, 1.9],
          easing: "linear",
          duration: 1800,
          delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
        });

        setTimeout(function () {
          div.remove();
        }, 1900);
      }, 700);
    },
    openTheLuckyGiftBox(day) {
      this.luckyGiftIndex = day + 1;
      this.currentGiftBox = this.activityInfo.points[day];

      if (this.currentGiftBox.gift_box_text.indexOf("span") < 0) {
        this.currentGiftBox.gift_box_text =
          this.currentGiftBox.gift_box_text.replace(
            /([0-9]+)/gi,
            "<span>$1</span>"
          );
      }

      this.judgeCollectPoints();
    },
    luckyGiftCloseBtnClick() {
      this.showLuckyGift = false;
      clearInterval(this.luckyGiftAni);
    },
    eventsCenterBtnClick() {
      anime({
        targets: ".events-center-btn",
        scale: [1.2, 1],
        // easing: "linear",
        duration: 800,
        delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
      });
      router.push("/eventsCenter");
    },
    clockInRecordBtnClick() {
      anime({
        targets: ".personal-center-btn",
        scale: [1.2, 1],
        // easing: "linear",
        duration: 800,
        delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
      });
      router.push("/clockInRecord");
    },
    clockInBtnClick() {
      if (this.activityInfo.nowClockinLog) {
        anime({
          targets: ".successfully_clockedin",
          scale: [1.2, 1],
          // easing: "linear",
          duration: 800,
          delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
        });
        return;
      }

      // eslint-disable-next-line no-unreachable
      anime({
        targets: ".click-to-clockin-btn",
        scale: [1.2, 1],
        // easing: "linear",
        duration: 800,
        delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
      });
      router.push({
        name: "clockIn",
        params: {
          id: this.activityInfo.id,
        },
      });
    },
    modalCloseBtnClick() {
      this.showModalName = null;
    },
    showModal(modalName) {
      console.log(modalName);
      this.showModalName = modalName;
    },
  },
};
</script>

<style scoped lang="less">
@import "less/ClockInMap.less";

.events-center-btn-delay-500 {
  animation-delay: 0.5s !important;
}

.events-center-btn-delay-1000 {
  animation-delay: 1s !important;
}
</style>

<style lang="less">
@import "../../less/Modal.less";
</style>
