<template>
  <div>
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="loadingBox" v-if="show">
        <div class="loading">
          <vue-loading
            type="bars"
            color="#f4364c"
            :size="{ width: '50px', height: '50px' }"
          ></vue-loading>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["show"],
};
</script>

<style scoped lang="less">
.animate__fadeInUp {
  animation-duration: 0.5s;
}

.animate__lightSpeedInRight {
  animation-duration: 0.5s;
}

.loadingBox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(255, 255, 255, 1);
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
