<template>
  <div style="position: relative">
    <div class="head-box">
      <img class="w-full" src="../../assets/personalCenter/head_2.jpg" />
      <div class="userinfo-box">
        <div class="name">{{ $userInfo && $userInfo.name }}</div>
        <div class="company">{{ $userInfo && $userInfo.company }}</div>
        <div class="email">{{ $userInfo && $userInfo.email }}</div>
      </div>
    </div>
    <!--<div class="userinfo-box">-->
    <!--  <div class="name">{{ userInfo.name }}</div>-->
    <!--  <div class="company">{{ userInfo.company }}</div>-->
    <!--  <div class="email">{{ userInfo.email }}</div>-->
    <!--</div>-->
    <div class="eventsList">
      <div v-for="event in eventsList" :key="event.id">
        <transition
          leave-active-class="animate__animated animate__bounceOut"
          enter-active-class="animate__animated animate__lightSpeedInRight "
          style="animation-delay: 1s !important"
        >
          <div v-if="!loading">
            <!--clockInMap-->
            <div
              class="eventItem"
              :class="[
                event.status === 1 ? 'border-gray' : '',
                event.status === 2 ? 'border-red' : '',
              ]"
              @click="
                event.status === 2
                  ? eventItemClick($event, event)
                  : showModal('123', event.activity_introduction)
              "
            >
              <div class="time-box">
                <img
                  v-if="event.status === 1"
                  class="bg"
                  src="../../assets/personalCenter/progress_start.png"
                />
                <img
                  v-if="event.status === 2"
                  class="bg"
                  src="../../assets/personalCenter/progress_in.png"
                />

                <div class="time">
                  {{ event.start_time | dateDDMM }}-{{
                    event.end_time | dateDDMM
                  }}
                </div>
              </div>
              {{ event.name }}
            </div>
          </div>
        </transition>
      </div>
    </div>

    <transition
      leave-active-class="animate__animated animate__fadeInDown"
      enter-active-class="animate__animated animate__fadeInUp"
    >
      <div class="personal-center-btn-box" v-if="!loading">
        <div
          style="
            color: #ababab;
            font-size: 11px;
            text-align: center;
            margin-bottom: 18px;
          "
          v-if="eventsList.length > 3"
        >
          向下滑动，查看更多活动
        </div>
        <router-link to="/clockInRecord">
          <div class="personal-center-btn">
            个人中心
            <div
              class="sheep-box"
              :class="$userInfo.inform === 0 ? 'without-notice' : ''"
            >
              <div class="notice">
                {{ $userInfo.inform }}
              </div>
              <img class="sheep" src="../../assets/personalCenter/sheep.png" />
            </div>
          </div>
        </router-link>
      </div>
    </transition>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <ActivityDescription
        @modalCloseBtnClick="modalCloseBtnClick"
        v-if="showModalName != null"
        :content="content"
      ></ActivityDescription>
    </transition>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import router from "@/router";
import anime from "animejs";
import ActivityDescription from "@/components/ActivityDescription";
import Loading from "@/components/Loading";

export default {
  name: "PersonalCenter",
  // eslint-disable-next-line vue/no-unused-components
  components: { ActivityDescription, Loading },
  data: function () {
    return {
      loading: true,
      showModalName: null,
      eventsList: [],
      content: "",
      // userInfo: this.$userInfo,
    };
  },
  filters: {
    dateDDMM: function (value) {
      if (!value) return "";
      value = value.replace(/-/g, "/");

      return value.substring(value.length - 5, value.length);
    },
  },
  mounted() {
    // if (this.$route.query.code) {
    //   localStorage.setItem("code", this.$route.query.code);
    // }
    this.getActivityList();
  },
  methods: {
    getActivityList() {
      let that = this;
      this.$api.get("activity.activity/getActivityList", null, (r) => {
        const arr = r.data.data;
        for (const index in arr) {
          // arr[index].status = 1;

          arr[index].status = this.calculateActivityStatus(
            arr[index].start_time,
            arr[index].end_time
          );
        }
        that.eventsList = arr;

        setTimeout(function () {
          that.loading = false;
        }, 500);
      });
    },
    calculateActivityStatus(start_date, end_date) {
      start_date = start_date.replace(/-/g, "/");
      end_date = end_date.replace(/-/g, "/");
      var myDate = new Date().valueOf();
      var start_time = new Date(start_date + " 00:00:00").valueOf();
      var end_time = new Date(end_date + " 23:59:59").valueOf();

      if (myDate < start_time) {
        return 1; //未开始
      } else if (myDate > end_time) {
        return 3; // 已结束
      } else if (myDate > start_time) {
        return 2; // 进行中
      }
    },
    modalCloseBtnClick() {
      this.showModalName = null;
    },
    showModal(modalName, content) {
      console.log(modalName);
      this.showModalName = modalName;
      this.content = content;

      // localStorage.setItem(
      //   "activity_" + that.activityInfo.id,
      //   JSON.stringify(that.activityInfo)
      // );
    },
    eventItemClick(e, activity) {
      if (activity.activity_type === 1) {
        anime({
          targets: e.currentTarget,
          scale: [1.2, 1],
          // easing: "linear",
          duration: 1000,
          delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
        });
        setTimeout(function () {
          router.push({ name: "clockInMap", params: { id: activity.id } });
        }, 300);
      }

      if (activity.activity_type === 2) {
        anime({
          targets: e.currentTarget,
          scale: [1.2, 1],
          // easing: "linear",
          duration: 1000,
          delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
        });

        // localStorage.setItem(
        //   "activity_" + activity.id,
        //   JSON.stringify(activity)
        // );

        setTimeout(function () {
          router.push({
            name: "clockIn",
            query: {
              type: activity.activity_type,
            },
            params: { id: activity.id },
          });
        }, 300);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "EventsCenter.less";

.animate__fadeInUp {
  animation-duration: 0.5s;
}

.animate__lightSpeedInRight {
  animation-duration: 0.5s;
}

.animate__delay {
  animation-delay: 0.5s !important;
}
</style>
