<template>
  <div class="Login">
    <img class="w-full" src="../../assets/login/abcam-login_02.png" />
    <div class="tips" v-if="$userInfo.old_user == 1">
      *请确认您的信息，邮箱需是您的绚星邮箱
    </div>

    <div class="form-container">
      <div class="form-plane">
        <div class="input-list">
          <div class="input-item">
            <img class="icon" src="../../assets/login/abcam-login_05.png" />
            <input
              class="input-text"
              @input="inputChange($event, 'name')"
              type="text"
              placeholder="姓名"
              v-model="formData.name"
            />
          </div>

          <div class="input-item">
            <img class="icon" src="../../assets/login/abcam-login_08.png" />
            <select
              class="input-select"
              :class="formData.area !== '' ? 'sel' : ''"
              @change="areaSelectChange($event)"
              v-if="$userInfo.old_user == 0"
            >
              <option disabled selected value="">区域</option>
              <option
                :value="index"
                v-for="(area, index) in areaList"
                :key="index"
              >
                {{ area.area }}
              </option>
            </select>

            <select
              class="input-select"
              :class="formData.area !== '' ? 'sel' : ''"
              @change="areaSelectChange($event)"
              v-if="$userInfo.old_user == 1"
            >
              <option disabled value="">区域</option>
              <option
                :value="index"
                v-for="(area, index) in areaList"
                :key="index"
                v-bind:selected="userinfo.area_id == area.id"
              >
                {{ area.area }}
              </option>
            </select>
          </div>

          <div class="input-item">
            <img class="icon" src="../../assets/login/abcam-login_14.png" />
            <select
              class="input-select"
              :class="formData.company !== '' ? 'sel' : ''"
              @change="selectChange($event, 'company')"
              v-if="$userInfo.old_user == 0"
            >
              <option disabled selected value="">公司</option>
              <option
                :value="dealer.id"
                v-for="(dealer, index) in dealerList"
                :key="index"
              >
                {{ dealer.name }}
              </option>
            </select>

            <select
              class="input-select"
              :class="formData.company !== '' ? 'sel' : ''"
              @change="selectChange($event, 'company')"
              v-if="$userInfo.old_user == 1"
            >
              <option disabled selected value="">公司</option>
              <option
                :value="dealer.id"
                v-for="(dealer, index) in dealerList"
                :key="index"
                v-bind:selected="userinfo.company_id == dealer.id"
              >
                {{ dealer.name }}
              </option>
            </select>
          </div>

          <div class="input-item">
            <img class="icon" src="../../assets/login/abcam-login_16.png" />
            <input
              class="input-text"
              type="email"
              placeholder="绚星登录邮箱"
              @input="inputChange($event, 'email')"
              v-model="formData.email"
            />
          </div>
          <div class="input-item">
            <img class="icon" src="../../assets/login/abcam-login_18.png" />
            <input
              class="input-text"
              type="number"
              placeholder="手机"
              @input="inputChange($event, 'phone')"
              v-model="formData.phone"
            />
          </div>
        </div>
      </div>
      <div
        class="submit-btn"
        @click="submitClick"
        v-if="$userInfo.old_user == 0"
      >
        注册
      </div>
      <div
        class="submit-btn"
        @click="submitClick"
        v-if="$userInfo.old_user == 1"
      >
        确认并登录
      </div>

      <div class="tips2">
        <img class="icon" src="../../assets/login/abcam-login_21.png" />
        如有任何问题，请在绚星微信群内联系工作人员
      </div>

      <Loading2 :show="loading"></Loading2>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Vue from "vue";

export default {
  name: "Login",
  components: {},
  data: function () {
    return {
      loading: false,
      formData: {
        name: "",
        area: "",
        areaId: "",
        company: "",
        email: "",
        phone: "",
      },
      userinfo: {},
      areaList: [],
      dealerList: [],
    };
  },
  mounted() {
    var that = this;
    var temp = setInterval(function () {
      if (that.$userInfo) {
        if (that.$userInfo.login_status === 1) {
          router.replace("/");
        } else {
          if (that.$userInfo.old_user === 1) {
            // return;
            that.formData.name = that.$userInfo.name;
            that.formData.area = that.$userInfo.area;
            that.formData.company = that.$userInfo.company_id;
            that.formData.email = that.$userInfo.email;
            that.formData.phone = that.$userInfo.phone;
            that.userinfo = that.$userInfo;

            that.getDealerList(that.userinfo.area_id);
            that.$forceUpdate();
          }
        }
        clearInterval(temp);
      }
    }, 100);
    this.getAreaList();
  },
  methods: {
    getAreaList() {
      var that = this;
      this.$api.get("user.login/getArea", null, (r) => {
        console.log(r);
        that.areaList = r.data.data;
      });
    },
    getDealerList(areaId) {
      var that = this;
      this.$api.get("user.login/getDealer", { area_id: areaId }, (r) => {
        console.log(r);
        that.dealerList = r.data.data;
      });
    },
    selectChange(dom, field) {
      this.formData[field] = dom.target.value;
    },
    areaSelectChange(dom) {
      var area = this.areaList[dom.target.value];
      this.formData["area"] = area.area;
      this.getDealerList(area.id);
    },
    inputChange(dom, field) {
      this.formData[field] = dom.target.value;
    },
    submitClick() {
      this.loading = true;
      var that = this;

      // this.formData['area'] =

      // if (this.$userInfo.old_user === 1) {
      //   this.formData["old_user"] = 1;
      // } else {
      //   this.formData["old_user"] = 0;
      // }

      this.$api.post(
        "user.login/login",
        this.formData,
        (r) => {
          console.log(r.data.data);
          that.$userInfo = r.data.data.userInfo;
          Vue.prototype.$userInfo = r.data.data.userInfo;
          // Vue.prototype.$userInfo.company =

          var company = "";

          for (const i in that.dealerList) {
            if (that.dealerList[i].id == that.formData.company) {
              company = that.dealerList[i].name;
            }
          }
          Vue.prototype.$userInfo.company = company;
          router.replace("/");
          that.loading = false;
        },
        (data) => {
          alert(data.msg);
          that.loading = false;
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import "Login.less";
</style>
