<template>
  <div style="position: relative"></div>
</template>

<script>
export default {
  name: "Exit",
  data: function () {
    return {};
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("code");
  },
};
</script>
