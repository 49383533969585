<template>
  <div>
    <div class="head">
      <img class="img w-full" src="../../assets/clockin/head.png" />
      <div class="text">
        {{ activityInfo && activityInfo.daily_message.text }}
      </div>
    </div>

    <div class="container">
      <img
        class="activity-description"
        @click="showModal('activity-description-modal')"
        src="../../assets/clockin/map/activity_description2.png"
        v-if="type === '2'"
      />
      <div class="img-container">
        <div class="img-container-border">
          <div
            class="img-cover"
            :style="'background-image:url(' + imgUrl + ')'"
          >
            <div class="plane" v-if="approvalRes">
              <img
                class="icon"
                v-if="
                  approvalRes &&
                  (approvalRes.approvalStatus == 1 ||
                    approvalRes.approvalStatus == 3)
                "
                src="../../assets/icon_01.png"
              />
              <img
                class="icon"
                v-if="approvalRes && approvalRes.approvalStatus == 2"
                src="../../assets/icon_02.png"
              />
            </div>
          </div>
          <div
            style="
              color: #494848;
              font-size: 15px;
              text-align: center;
              margin-top: 5px;
            "
            :style="
              approvalRes && approvalRes.approvalStatus != 1
                ? 'color: red;'
                : ''
            "
          >
            &nbsp; {{ approvalRes && approvalRes.msg }}&nbsp;
          </div>

          <div
            style="
              color: #494848;
              font-size: 10px;
              text-align: center;
              margin-top: 5px;
            "
            :style="
              approvalRes && approvalRes.approvalStatus != 1
                ? 'color: red;'
                : ''
            "
          >
            &nbsp;{{ approvalRes && approvalRes.msg_desc }}&nbsp;
          </div>

          <div
            class="progress-bar-box"
            :style="'opacity:' + (!uploadStatus ? 0 : 1)"
          >
            <span>审</span>
            <span style="animation-delay: 0.7s">核</span>
            <span style="animation-delay: 1.4s">中</span>
            <span style="animation-delay: 2.1s">.</span>
            <span style="animation-delay: 2.8s">.</span>
            <span style="animation-delay: 3.5s">.</span>
          </div>
          <!--<div-->
          <!--  class="progress-bar-box"-->
          <!--  :style="'opacity:' + (uploadProgress === 0 ? 0 : 1)"-->
          <!--&gt;-->
          <!--  <div-->
          <!--    class="progress-bar"-->
          <!--    id="progress-bar"-->
          <!--    :style="'width:' + uploadProgress + '%;'"-->
          <!--  ></div>-->
          <!--  <img-->
          <!--    class="icon"-->
          <!--    id="icon"-->
          <!--    :style="'left:' + uploadProgress + '%;'"-->
          <!--    src="../../assets/icon_01.png"-->
          <!--    alt=""-->
          <!--  />-->
          <!--</div>-->
        </div>
      </div>

      <div class="btn-container">
        <div class="btn btn-return" @click="returnBtnClick()">
          <div>返回上页</div>
        </div>
        <div
          class="btn btn-upload"
          :class="
            (activityInfo && activityInfo.nowClockinLog) ||
            (approvalRes &&
              (approvalRes.approvalStatus == 1 ||
                approvalRes.approvalStatus == 3))
              ? 'disable'
              : ''
          "
        >
          <img
            class="successfully_clockedin"
            src="../../assets/clockin/map/text_successfully_clockedin.png"
            style="transform: scale(0)"
          />
          <!--style="transform: scale(0)"-->

          <input
            v-if="
              !uploadStatus &&
              activityInfo &&
              !activityInfo.nowClockinLog &&
              (!approvalRes ||
                (approvalRes &&
                  (approvalRes.approvalStatus !== 1 ||
                    approvalRes.approvalStatus !== 3)))
            "
            class="input-file"
            type="file"
            ref="inputer"
            @change="addImg"
          />

          <!--accept="image/png,image/jpeg,image/gif,image/jpg"-->
          <div
            v-if="activityInfo && activityInfo.nowClockinLog"
            @click="addImg2"
            class="input-file"
          ></div>

          上传截图
        </div>
      </div>

      <div class="tips2">
        <img class="icon" src="../../assets/login/abcam-login_21.png" />
        如有任何问题，请在绚星微信群内联系工作人员
      </div>
    </div>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <ActivityDescription
        @modalCloseBtnClick="modalCloseBtnClick"
        v-if="showModalName != null"
        :content="activityInfo && activityInfo.activity_introduction"
      ></ActivityDescription>
    </transition>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="modals" v-if="activityPrompt01Status">
        <div class="plane"></div>
        <div
          class="modal activity-prompt-01-modal"
          style="padding: 7vw 3vw 13vw"
        >
          <img
            class="close-btn"
            @click="modal2CloseBtnClick()"
            src="@/assets/close_icon.png"
          />

          <div class="content">
            <div class="title">审核成功</div>
            <div class="btn" @click="openGifBoxClick()">
              <div>抽盲盒，领积分！</div>
            </div>
          </div>
          <!--<img src="../../assets/clockin/record/modal_bg_01.jpg" height="207" width="592" />-->
        </div>
      </div>
    </transition>

    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="modals" v-if="showLuckyGift">
        <div class="plane"></div>
        <div
          class="modal lucky-gift-box-modal"
          :class="collectPointsRes ? 'open' : ''"
        >
          <img class="bg_title" src="@/assets/modal/bg_title2.png" alt="" />
          <img
            class="close-btn"
            style="z-index: 999"
            @click="luckyGiftCloseBtnClick()"
            src="@/assets/close_icon.png"
          />

          <!--<div class="text">-->
          <!--  激动的心颤抖的手， <br />-->
          <!--  积分马上就到手！ <br />-->
          <!--  最高可抽取<span style="color: #eb5e32">{{-->
          <!--    currentGiftBox && currentGiftBox.integral-->
          <!--  }}</span-->
          <!--  >积分哦！-->
          <!--</div>-->

          <div
            class="text"
            style="white-space: pre-line"
            v-html="currentGiftBox && currentGiftBox.gift_box_text"
          ></div>

          <img
            class="openBtn"
            @click="collectPointsClick"
            src="../../assets/modal/open.png"
            alt=""
          />

          <div class="integral-text-box" id="integral-text-box"></div>
        </div>
      </div>
    </transition>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import router from "@/router";
import ActivityDescription from "@/components/ActivityDescription";
import Loading from "@/components/Loading";
import anime from "animejs";
export default {
  name: "ClockIn",
  components: { ActivityDescription, Loading },
  data: function () {
    return {
      activityPrompt01Status: false,
      collectPointsStauts: false,
      uploadStatus: false,
      showModalName: null,
      showLuckyGift: false,
      collectPointsRes: null,
      currentGiftBox: null,
      clockinLog: null,
      imgUrl: null,
      type: "1",
      activityInfo: null,
      uploadProgress: 0,
      //审核信息
      approvalInformation: "",
      approvalRes: null,
      //审核状态
      approvalStatus: 0,
      loading: true,
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type + "";
    }

    this.getActivityById();

    // this.activityInfo = JSON.parse(
    //   localStorage.getItem("activity_" + this.$route.params.id)
    // );
    //
    // if (!this.activityInfo) {
    //
    // }
  },
  methods: {
    collectPointsClick() {
      if (this.collectPointsStauts) return;
      // eslint-disable-next-line no-unused-vars
      this.collectPointsStauts = true;

      const that = this;
      this.$api.post(
        "activity.activity/collectPoints",
        { id: this.$route.params.id, pointIndex: this.clockinLog.day },
        (r) => {
          console.log(r);
          this.collectPointsStauts = false;
          that.collectPointsRes = r.data.data;
          that.collectPointsAnimation(that.collectPointsRes.giftbox_integral);
        }
      );
    },
    //领取积分的动画
    collectPointsAnimation(integral) {
      this.luckyGiftAni = setInterval(function () {
        var div = document.createElement("div");
        div.className = "integral-text";
        div.innerText = "+" + integral + "积分";
        var container = document.getElementById("integral-text-box");
        container.appendChild(div);

        anime({
          targets: div,
          bottom: ["0%", "100%"],
          opacity: [0.3, 1, 0],
          scale: [1.3, 1.6, 1.9],
          easing: "linear",
          duration: 1800,
          delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
        });

        setTimeout(function () {
          div.remove();
        }, 1900);
      }, 700);
    },
    // openTheLuckyGiftBox(day) {
    //   this.luckyGiftIndex = day + 1;
    //   this.currentGiftBox = this.activityInfo.points[day];
    //
    //   this.judgeCollectPoints();
    // },

    //判断是否领取积分
    judgeCollectPoints(luckyGiftIndex) {
      const that = this;
      this.$api.post(
        "activity.activity/judgeCollectPoints",
        { id: this.$route.params.id, pointIndex: luckyGiftIndex },
        (r) => {
          console.log(r);
          var data = r.data.data;

          if (data.is_receive_giftbox != 1) {
            that.showLuckyGift = true;
            setTimeout(function () {
              anime({
                targets: ".openBtn",
                scale: [0.7, 1],
                loop: true,
                duration: 1800,
                delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
              });
            }, 500);
          }
        }
      );
    },
    luckyGiftCloseBtnClick() {
      this.showLuckyGift = false;
      this.activityPrompt01Status = false;
      clearInterval(this.luckyGiftAni);
    },
    getActivityById() {
      var that = this;
      this.loading = true;
      this.$api.get(
        "activity.activity/getActivityById",
        { id: this.$route.params.id },
        (r) => {
          that.activityInfo = r.data.data;

          setTimeout(function () {
            that.loading = false;
          }, 500);
        }
      );
    },
    addImg2() {
      anime({
        targets: ".successfully_clockedin",
        scale: [1.2, 1],
        // easing: "linear",
        duration: 800,
        delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
      });
    },
    addImg(event) {
      // var progressbar = document.getElementById("progress-bar");
      // console.log(progressbar);
      // var icon = document.getElementById("icon");
      // progressbar.setAttribute("style", "transition: all 0s !important");
      // icon.setAttribute("style", "transition: all 0s !important");
      //
      // this.uploadProgress = 0;
      //
      // setTimeout(function () {
      //   progressbar.setAttribute("style", "transition: all 0.8s !important");
      //   icon.setAttribute("style", "transition: all 0.8s !important");
      // }, 300);

      this.uploadStatus = true;

      console.log(event.target.files[0]);
      var url = this.getFileURL(event.target.files[0]);
      console.log(url);
      this.imgUrl = url;
      // this.uploadProgress = 50;
      this.approvalRes = null;
      var that = this;

      // var inter = setInterval(function () {
      //   that.uploadProgress = that.uploadProgress + 1;
      //
      //   if (that.uploadProgress > 90) {
      //     clearInterval(inter);
      //   }
      // }, 50);
      let param = new FormData(); // 创建form对象
      param.append("image", event.target.files[0]); // 通过append向form对象添加数据
      param.append("id", this.$route.params.id); // 通过append向form对象添加数据

      this.$api.post(
        "activity.activity/uploadScreenshot",
        param,
        (r) => {
          console.log(r);
          that.uploadProgress = 100;
          that.uploadStatus = false;
          // clearInterval(inter);
          // that.approvalInformation = r.data.data.msg;
          // that.approvalStatus = r.data.data.approvalStatus;

          that.approvalRes = r.data.data;

          if (r.data.data.approvalStatus == 1) {
            var clockinLog = r.data.data.clockinLog;
            that.currentGiftBox = r.data.data.giftBox;

            if (that.currentGiftBox.gift_box_text.indexOf("span") < 0) {
              that.currentGiftBox.gift_box_text =
                that.currentGiftBox.gift_box_text.replace(
                  /([0-9]+)/gi,
                  "<span>$1</span>"
                );
            }

            if (r.data.data.giftBox.type == 2) {
              that.clockinLog = clockinLog;
              that.activityPrompt01Status = true;
            }

            // if (
            //   clockinLog.is_giftbox === 1 &&
            //   clockinLog.is_receive_giftbox === 0
            // ) {
            //   that.showLuckyGift = true;
            //
            //   setTimeout(function () {
            //     anime({
            //       targets: ".openBtn",
            //       scale: [0.7, 1],
            //       loop: true,
            //       duration: 1800,
            //       delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
            //     });
            //   }, 500);
            // }
          }
        },
        (data) => {
          alert(data.msg);
          that.approvalRes = null;
          that.uploadProgress = 0;
          that.imgUrl = "";
        }
      );
    },
    getFileURL(file) {
      var getUrl = null;
      if (window.createObjectURL !== undefined) {
        // basic
        getUrl = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        getUrl = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        getUrl = window.webkitURL.createObjectURL(file);
      }
      return getUrl;
    },
    openGifBoxClick() {
      var clockinLog = this.clockinLog;
      if (clockinLog.is_giftbox === 1 && clockinLog.is_receive_giftbox === 0) {
        this.showLuckyGift = true;

        setTimeout(function () {
          anime({
            targets: ".openBtn",
            scale: [0.7, 1],
            loop: true,
            duration: 1800,
            delay: anime.stagger(100), // 每个元素的延迟增加100毫秒。
          });
        }, 500);
      }
    },
    returnBtnClick() {
      // router.go(-1);
      router.go(-1);
      //
      // if (this.type === "1") {
      //   // router.push("/clockInMap");
      //   router.go(-1);
      // }
      // if (this.type === "2") {
      //   router.push("/eventsCenter");
      // }
    },
    modalCloseBtnClick() {
      this.showModalName = null;
    },
    modal2CloseBtnClick() {
      this.activityPrompt01Status = false;
    },
    showModal(modalName) {
      console.log(modalName);
      this.showModalName = modalName;
    },
  },
};
</script>

<style scoped lang="less">
@import "less/ClockIn.less";
@import "../../less/Modal.less";
</style>
