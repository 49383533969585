<template>
  <div class="modals">
    <div class="plane"></div>
    <div class="modal activity-description-modal">
      <img class="bg_title" src="@/assets/modal/bg_title.png" alt="" />
      <img
        class="close-btn"
        @click="modalCloseBtnClick()"
        src="@/assets/close_icon.png"
      />

      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityDescription",
  props: ["content"],
  methods: {
    modalCloseBtnClick() {
      this.$emit("modalCloseBtnClick");
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/Modal.less";

.content {
}
</style>
