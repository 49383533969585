<template>
  <div>
    <div class="head-container">
      <img class="w-full" src="../../assets/clockin/leaderboard/head.jpg" />

      <div class="userinfo-box">
        <div class="name">{{ $userInfo && $userInfo.name }}</div>
        <div class="company">{{ $userInfo && $userInfo.company }}</div>
      </div>
    </div>
    <div class="container">
      <div class="rank-box">
        <span class="rank-text">
          您现在处于第<span class="number">{{ personalRankingOfTheMonth }}</span
          >名
        </span>
      </div>

      <div class="integral-rank-container">
        <div class="integral-rank-box">
          <vuescroll :ops="ops">
            <div class="integral-rank-list">
              <div
                class="integral-rank-item"
                v-for="(item, index) in rankingOfTheMonth"
                :key="index"
              >
                <div class="integral-rank-item-item name">
                  第{{ numberfilter(item.index) }}名
                </div>
                <div class="integral-rank-item-item date">
                  {{ item.name }}
                </div>
                <div class="integral-rank-item-item status">
                  {{ item.integral }}积分
                </div>
              </div>
            </div>
          </vuescroll>
        </div>
      </div>

      <div class="footer-container">
        <div class="btn-container">
          <router-link to="/clockInRecord" class="btn btn-personal-center">
            <div>个人中心</div>
          </router-link>
          <router-link to="/eventsCenter" class="btn btn-events-center">
            <div>活动中心</div>
          </router-link>
        </div>
        <Tips></Tips>
      </div>
    </div>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import Tips from "@/components/Tips";
import vuescroll from "vuescroll/dist/vuescroll-native";

import Loading from "@/components/Loading";
export default {
  name: "Leaderboard",
  components: { vuescroll, Tips, Loading },
  data: function () {
    return {
      rankingOfTheMonth: [],
      personalRankingOfTheMonth: "",
      loading: true,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: "white",
          opacity: 1,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "0",
          keepShow: false,
        },
        bar: {
          keepShow: true,
          background: "#f2f2f2",
          size: "4px",
        },
      },
    };
  },
  mounted() {
    var that = this;
    this.$api.get("user.login/getRankingOfTheMonth", null, (r) => {
      var arr = r.data.data.rankingOfTheMonth;
      var rankingOfTheMonth = [];

      var indexArr = [];
      var index = 0;
      that.personalRankingOfTheMonth = r.data.data.personalRankingOfTheMonth;
      for (const arrKey in arr) {
        var item = arr[arrKey];

        if (indexArr.indexOf(item.integral1) < 0) {
          index++;
        }
        indexArr.push(item.integral1);

        rankingOfTheMonth.push({
          integral: item.integral1,
          name: item.name,
          index: index,
        });
      }

      that.rankingOfTheMonth = rankingOfTheMonth;
      that.loading = false;
    });
  },
  methods: {
    numberfilter(num) {
      const changeNum = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
      ]; // changeNum[0] = "零"
      const unit = ["", "十", "百"];
      num = parseInt(num);
      const getWan = (temp) => {
        const strArr = temp.toString().split("").reverse();
        let newNum = "";
        for (var i = 0; i < strArr.length; i++) {
          newNum =
            (i == 0 && strArr[i] == 0
              ? ""
              : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
              ? ""
              : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
            newNum;
        }
        return newNum;
      };
      const overWan = Math.floor(num / 100);
      let noWan = num % 100;
      if (noWan.toString().length < 2) noWan = "0" + noWan;
      let strr = overWan ? getWan(overWan) + "百" + getWan(noWan) : getWan(num);
      if (strr.split("")[0] == "一") {
        let showNum = "";
        if (strr == "一") {
          showNum = strr.substring(0);
        } else {
          showNum = strr.substring(1);
        }
        return showNum;
      } else {
        let showNum = overWan
          ? getWan(overWan) + "百" + getWan(noWan)
          : getWan(num);
        return showNum;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "less/Leaderboard.less";
</style>

<style>
.__vuescroll {
  overflow: inherit !important;
  position: static !important;
}

.__bar-wrap-is-vertical {
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.__hidebar {
  overflow: scroll !important;
}
</style>
