import MyLoading from "./Loading.vue";
// 这里是重点
const Loading = {
  install: function (Vue) {
    Vue.component("Loading2", MyLoading);
  },
};

// 导出组件
export default Loading;
