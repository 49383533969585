import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/login/Login";
import EventsCenter from "@/views/eventsCenter/EventsCenter";
import ClockIn from "@/views/clockin/ClockIn";
import ClockInRecord from "@/views/clockin/ClockInRecord";
import Leaderboard from "@/views/clockin/Leaderboard";
import ClockInMap from "@/views/clockin/ClockInMap";
import Preview from "@/views/clockin/Preview";
import Exit from "@/views/clockin/Exit";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: EventsCenter,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/eventsCenter",
    component: EventsCenter,
  },
  {
    path: "/clockIn/:id",
    name: "clockIn",
    component: ClockIn,
  },
  {
    path: "/clockInRecord",
    component: ClockInRecord,
  },
  {
    path: "/leaderboard",
    component: Leaderboard,
  },
  {
    path: "/clockInMap/:id",
    name: "clockInMap",
    component: ClockInMap,
  },
  {
    path: "/preview/:id",
    name: "preview",
    component: Preview,
  },
  {
    path: "/exit",
    name: "exit",
    component: Exit,
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
