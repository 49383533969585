<template>
  <div style="position: relative">
    <!--<img class="bg" src="../../assets/clockin/map/bg.jpg" />-->

    <div style="opacity: 0; position: absolute; top: -1000px">
      <img src="@/assets/modal/gift_box_bg_1.jpg" style="width: 100%" alt="" />
    </div>
    <img
      class="activity-description"
      @click="showModal('activity-description-modal')"
      src="../../assets/clockin/map/activity_description3.png"
    />
    <div class="cust-container">
      <div
        class="map-container"
        v-if="activityInfo"
        :style="'background-image:url(' + activityInfo.bg_img + ')'"
      >
        <div class="map-box">
          <img class="map-img" :src="activityInfo.map_img" />

          <div class="ornaments">
            <img
              class="ornament-item"
              v-for="(item, index) in activityInfo.pendants"
              :key="index"
              :src="item.url"
              :style="
                'top: ' +
                item.y +
                ';left:' +
                item.x +
                ';width:' +
                item.width +
                ';'
              "
            />
          </div>

          <div class="points">
            <!--:src="item.status ? item.url1 : item.url2"-->
            <span v-for="(item, index) in activityInfo.points" :key="index">
              <div
                v-if="item.type == 1"
                class="point-item"
                style="width: 2.5%"
                :style="'top:' + item.y + '%;left:' + item.x + '%;'"
              >
                <img
                  :src="
                    activityInfo.numberOfDaysCheckedIn > index
                      ? item.point_2_img
                      : item.point_1_img
                  "
                  style="pointer-events: none; width: 100%; display: block"
                />
              </div>

              <div
                v-if="item.type == 2"
                class="point-item"
                style="width: 2.5%"
                :style="
                  'top:' +
                  item.y +
                  '%;left:' +
                  item.x +
                  '%;width:' +
                  item.icon.width +
                  ';'
                "
              >
                <img
                  :src="
                    activityInfo.numberOfDaysCheckedIn > index
                      ? item.icon.point_2_img
                      : item.icon.point_1_img
                  "
                  style="pointer-events: none; width: 100%; display: block"
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-container">
      <div class="btn-container">
        <div class="btn events-center-btn">
          <transition enter-active-class="animate__animated animate__fadeInUp ">
            <div v-if="!loading">
              <!--<router-link to="/eventsCenter">-->
              <img src="../../assets/clockin/map/text_events_center.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
        <div class="btn personal-center-btn">
          <transition
            enter-active-class="animate__animated animate__fadeInUp events-center-btn-delay-500"
          >
            <div v-if="!loading">
              <!--<router-link to="/clockInRecord">-->
              <img src="../../assets/clockin/map/text_personal_center.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
        <div class="btn click-to-clockin-btn">
          <transition
            enter-active-class="animate__animated animate__fadeInUp events-center-btn-delay-1000"
          >
            <div v-if="!loading">
              <img
                class="successfully_clockedin"
                src="../../assets/clockin/map/text_successfully_clockedin.png"
                style="transform: scale(0)"
              />

              <!--<router-link to="/clockIn">-->
              <img src="../../assets/clockin/map/text_click_to_clockin.png" />
              <!--</router-link>-->
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <ActivityDescription
        @modalCloseBtnClick="modalCloseBtnClick"
        v-if="showModalName != null"
        :content="activityInfo.activity_introduction"
      ></ActivityDescription>
    </transition>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import ActivityDescription from "@/components/ActivityDescription";
export default {
  name: "Preview",
  components: { ActivityDescription, Loading },
  data: function () {
    return {
      showLuckyGift: false,
      luckyGiftIndex: -1,
      currentGiftBox: null,
      showModalName: null,
      loading: true,
      activityInfo: null,
      numberOfDaysCheckedIn: 5,
      luckyGiftAni: null,
      collectPointsRes: null,
    };
  },
  mounted() {
    let that = this;
    this.$api.get(
      "activity.activity/getPreviewById",
      { id: this.$route.params.id },
      (r) => {
        that.activityInfo = r.data.data;
        setTimeout(function () {
          that.loading = false;
        }, 500);
      }
    );
  },
  methods: {
    modalCloseBtnClick() {
      this.showModalName = null;
    },
    showModal(modalName) {
      console.log(modalName);
      this.showModalName = modalName;
    },
  },
};
</script>

<style scoped lang="less">
@import "less/ClockInMap.less";

.events-center-btn-delay-500 {
  animation-delay: 0.5s !important;
}

.events-center-btn-delay-1000 {
  animation-delay: 1s !important;
}
</style>

<style lang="less">
@import "../../less/Modal.less";
</style>
