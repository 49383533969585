<template>
  <div class="tips2">
    <img class="icon" src="../assets/login/abcam-login_21.png" />
    如有任何问题，请在绚星微信群内联系工作人员
  </div>
</template>

<script>
export default {
  name: "Tips",
};
</script>

<style scoped lang="less">
.tips2 {
  color: #9a9a9a;
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1px;
  line-height: 20px;

  .icon {
    height: 15px;
    vertical-align: middle;
  }
}
</style>
