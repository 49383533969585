import { render, staticRenderFns } from "./Leaderboard.vue?vue&type=template&id=baa11b9a&scoped=true&"
import script from "./Leaderboard.vue?vue&type=script&lang=js&"
export * from "./Leaderboard.vue?vue&type=script&lang=js&"
import style0 from "./Leaderboard.vue?vue&type=style&index=0&id=baa11b9a&scoped=true&lang=less&"
import style1 from "./Leaderboard.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa11b9a",
  null
  
)

export default component.exports