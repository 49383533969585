<template>
  <div>
    <div class="head-container">
      <img class="w-full" src="../../assets/clockin/record/logo.png" />

      <div class="userinfo-box">
        <div class="name">{{ $userInfo && $userInfo.name }}</div>
        <div class="company">{{ $userInfo && $userInfo.company }}</div>
        <!---->
        <div class="see-more" @click="showModal('userinfo-modal')">
          <span class="text">查看完整个人信息</span>
          <img class="icon" src="../../assets/clockin/record/icon_04.png" />
        </div>
      </div>

      <!--积分相关-->
      <div class="integral-box">
        <div class="accumulated-check-in-points">
          <img class="icon" src="../../assets/clockin/record/icon_01.png" />
          <span class="text">
            累计打卡积分:
            <span class="fraction">
              {{ clockRecordInfo.accumulatedCheckInPoints }}分
            </span>
          </span>
        </div>
        <div class="integral-operation-button">
          <div class="btn check-in-points-this-month">
            <img class="icon" src="../../assets/clockin/record/icon_02.png" />
            <div class="text">本月打卡积分</div>
            <div class="number">
              {{
                clockRecordInfo && clockRecordInfo.checkInPointsForTheMonth
              }}分
            </div>
            <img class="sheep" src="../../assets/clockin/record/icon_07.png" />
          </div>
          <div class="btn personal-ranking-of-the-month">
            <img class="icon" src="../../assets/clockin/record/icon_03.png" />
            <div class="text">本月个人排名</div>
            <div class="number">
              {{ clockRecordInfo && clockRecordInfo.personalRankingOfTheMonth }}
            </div>
          </div>
        </div>
        <div class="view-the-leaderboard">
          <router-link to="/leaderboard">
            <span class="text">点击查看本月排行榜</span>
            <img class="icon" src="../../assets/clockin/record/icon_05.png" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="check-in-log-container">
      <div class="check-in-log-box">
        <div class="check-in-log-title">
          <div class="check-in-log-title-item name">
            <div class="name-select">
              {{ selectActivityName || "活动名称" }}
              <img class="icon" src="../../assets/clockin/record/icon_06.png" />
              <select class="input-select" @change="nameSelectChange">
                <option value="" selected>活动名称</option>
                <option
                  :value="activity.activity_id + '-' + activity.activity_name"
                  v-for="(activity, index) in clockRecordInfo.activityList"
                  :key="index"
                >
                  {{ activity.activity_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="check-in-log-title-item date">积分</div>
          <div class="check-in-log-title-item status">打卡状态</div>
        </div>

        <div class="check-in-log-list">
          <vuescroll :ops="ops">
            <div
              class="check-in-log-item"
              v-for="(clockIn, index) in clockRecordInfo.clockInList"
              :key="index"
            >
              <div class="check-in-log-item-item name">
                {{ clockIn.activity_name }}
                <div style="font-size: 10px">
                  <i>{{ clockIn.create_time | formatTime }}</i>
                </div>
              </div>
              <div class="check-in-log-item-item date">
                <!--<span v-if="clockIn.is_receive_giftbox == 0">0</span>-->
                <!--<span v-else>{{ clockIn.integral }}</span>-->
                <span>{{ clockIn.integral }}</span>
              </div>
              <div class="check-in-log-item-item status">
                <span
                  v-if="clockIn.status === 9"
                  class="did-not-pass"
                  @click="didNotPassBtnClick(clockIn)"
                >
                  未通过
                </span>
                <span v-else>通过</span>
              </div>
            </div>
          </vuescroll>
        </div>
      </div>
    </div>

    <div class="activity-center-box">
      <router-link to="/eventsCenter">
        <div class="activity-center-btn">活动中心</div>
      </router-link>

      <Tips></Tips>
    </div>

    <!--弹窗-->
    <transition
      leave-active-class="animate__animated animate__fadeOut "
      enter-active-class="animate__animated animate__fadeIn "
    >
      <div class="modals" v-if="showModalName != null">
        <div class="plane"></div>

        <div
          class="modal did-not-pass-modal"
          v-if="showModalName === 'did-not-pass-modal'"
        >
          <img
            class="close-btn"
            @click="modalCloseBtnClick()"
            src="../../assets/close_icon.png"
          />
          <!--<img class="img" :src="didNotPasscClockIn.screenshot_path" alt="" />-->
          <div class="img" style="overflow: scroll; height: 220px">
            <img
              style="width: 100%"
              :src="didNotPasscClockIn.screenshot_path"
              alt=""
            />
          </div>
          <div class="text">
            {{ didNotPasscClockIn.check_msg_desc }}
          </div>
        </div>

        <div
          class="modal userinfo-modal"
          v-if="showModalName === 'userinfo-modal'"
        >
          <img
            class="close-btn"
            @click="modalCloseBtnClick()"
            src="../../assets/close_icon.png"
          />
          <div class="name">{{ $userInfo && $userInfo.name }}</div>
          <div class="info-list">
            <div class="info-item">
              <img
                class="icon"
                src="../../assets/clockin/record/modal/icon_01.png"
              />
              <span class="text">{{ $userInfo && $userInfo.area }}</span>
            </div>
            <div class="info-item">
              <img
                class="icon"
                src="../../assets/clockin/record/modal/icon_02.png"
              />
              <span class="text">{{ $userInfo && $userInfo.company }}</span>
            </div>
            <div class="info-item">
              <img
                class="icon"
                src="../../assets/clockin/record/modal/icon_03.png"
              />
              <span class="text">{{ $userInfo && $userInfo.email }}</span>
            </div>
            <div class="info-item">
              <img
                class="icon"
                src="../../assets/clockin/record/modal/icon_04.png"
              />
              <span class="text">{{ $userInfo && $userInfo.phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import Tips from "@/components/Tips";
import vuescroll from "vuescroll/dist/vuescroll-native";
import Loading from "@/components/Loading";

export default {
  name: "ClockInRecord",
  components: { Tips, vuescroll, Loading },
  data: function () {
    return {
      showModalName: null,
      loading: true,
      clockRecordInfo: {
        activityList: [],
        clockInList: [],
      },
      didNotPasscClockIn: null,
      selectActivityName: null,
      // userInfo: this.$userInfo,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: "white",
          opacity: 1,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "-5%",
          keepShow: false,
        },
        bar: {
          keepShow: true,
          background: "#f2f2f2",
          size: "4px",
        },
      },
    };
  },
  filters: {
    // eslint-disable-next-line no-unused-vars
    formatTime(time) {
      time = time.replace(/-/g, "/");
      let dt = new Date(time);
      let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
      let dd = dt.getDate().toString().padStart(2, "0");
      let h = dt.getHours().toString().padStart(2, "0");
      let m = dt.getMinutes().toString().padStart(2, "0");
      return MM + "月" + dd + "日 " + h + ":" + m;
    },
  },
  mounted() {
    this.getClockRecordInfo();

    if (this.$userInfo.inform !== 0) {
      this.noticeHaveRead();
      this.$userInfo.inform = 0;
    }
  },
  methods: {
    noticeHaveRead() {
      this.$api.get("user.login/noticeHaveRead", null, (r) => {
        console.log(r);
      });
    },
    nameSelectChange(e) {
      var value = e.target.value;
      var arr = value.split("-");
      this.selectActivityName = arr[1];
      var that = this;
      that.loading = true;
      this.$api.get("user.login/getClockinListById", { id: arr[0] }, (r) => {
        that.clockRecordInfo.clockInList = r.data.data;

        setTimeout(function () {
          that.loading = false;
        }, 500);
      });
    },
    getClockRecordInfo() {
      var that = this;
      this.$api.get("user.login/getCheckInRecordInfo", null, (r) => {
        that.clockRecordInfo = r.data.data;

        setTimeout(function () {
          that.loading = false;
        }, 500);
      });
    },
    modalCloseBtnClick() {
      this.showModalName = null;
    },
    showModal(modalName) {
      this.showModalName = modalName;
    },
    didNotPassBtnClick(clockIn) {
      this.didNotPasscClockIn = clockIn;
      this.showModalName = "did-not-pass-modal";
    },
    /**
     * 查看完整个人信息
     */
    viewUserInfoBtnClick() {},
  },
};
</script>

<style scoped lang="less">
@import "less/ClockInRecord.less";
@import "../../less/Modal.less";
.check-in-log-box {
  position: relative;
}
</style>

<style>
.__vuescroll {
  overflow: inherit !important;
  position: static !important;
}

.__bar-wrap-is-vertical {
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
</style>
