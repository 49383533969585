import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 引用API文件
import api from "./api/index.js";

// 重置浏览器默认样式
import "normalize.css";

import animated from "animate.css"; // npm install animate.css --save安装，在引入

import VueLoading from "vue-loading-template";
Vue.use(VueLoading /** options **/);

import Loading2 from "@/components/loading2";
Vue.use(Loading2);

Vue.prototype.$api = api;
// Vue.prototype.$host = "https://abcam-uat-api.ambitious.ltd";
Vue.prototype.$host = "https://abcam-api.ambitious.ltd";
// Vue.prototype.$host = "http://abcam.local:9000/";

Vue.use(animated);
Vue.config.productionTip = false;

Vue.prototype.$userInfo = {};

// localStorage.setItem(
//   "token",
//   "B#reI4Y^yxiXWByT5#Mi$H&Zj76euY8U5ArAJmsBjVRKHe09&xHae!LODDyeCA5r"
// );

window.alert = function (name) {
  var iframe = document.createElement("IFRAME");
  iframe.style.display = "none";
  iframe.setAttribute("src", "data:text/plain,");
  document.documentElement.appendChild(iframe);
  window.frames[0].window.alert(name);
  iframe.parentNode.removeChild(iframe);
};

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return null;
}

//判断是否是预览
if (window.location.href.indexOf("/preview/") >= 0) {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
} else {
  const code = getQueryVariable("code");
  if (code != null) {
    localStorage.setItem("code", code);
    // window.location.href = "https://abcam-uat.ambitious.ltd/";
    window.location.href = "https://abcam.ambitious.ltd/";
  }

  var getUserInfo = function () {
    // 你的逻辑代码
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      /**
       * 获取用户信息
       */
      api.get("user.login/getUserInfo", null, (r) => {
        let userInfo = r.data.data.userInfo;
        Vue.prototype.$userInfo = userInfo;

        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        //如果用户未注册，先去注册
        if (userInfo.login_status != 1) {
          router.replace("/login");
        }

        resolve();
      });
    });
  };

  getUserInfo().then(function () {
    new Vue({
      router,
      render: (h) => h(App),
    }).$mount("#app");
  });
}
